(function ($, window, document, undefined) {

    'use strict';

    var map;

    $(function () {
        $(document).ready(function() {

            if (!$('html').hasClass('mobile')) {
                var wow = new WOW({ mobile: false });
                wow.init();
            }

            $('#tarjetas-profesional .tarjetas-list__item').click(function(e) {
                e.preventDefault();
                var title = $(this).attr('title');
                if (!$(this).hasClass('active')) {
                    $('#tarjetas-profesional .tarjetas-list__item').removeClass('active');
                    $(this).addClass('active');
                    $('#tarjetas-profesional .fluid-panel__content h3').html(title);
                }
            });

            $('#tarjetas-particular .tarjetas-list__item').click(function(e) {
                e.preventDefault();
                var title = $(this).attr('title');
                if (!$(this).hasClass('active')) {
                    $('#tarjetas-particular .tarjetas-list__item').removeClass('active');
                    $(this).addClass('active');
                    $('#tarjetas-particular .fluid-panel__content h3').html(title);

                }
            });

            $('#btn-home-profesional').click(function() {
                if ($('#home-profesional').hasClass('js-hidden')) {
                    $('#home-particular').fadeOut('400', 'linear', function() {
                        $('#home-profesional').fadeIn('slow');
                        $('#home-profesional').removeClass('js-hidden');
                        $('#home-particular').addClass('js-hidden');
                    });
                    $('#btn-home-profesional').removeClass('btn-tertiary');
                    $('#btn-home-profesional').addClass('btn-primary');
                    $('#btn-home-particular').addClass('btn-tertiary');
                    $('#btn-home-particular').removeClass('btn-primary');
                }
            });

            $('#btn-home-particular').click(function() {
                if ($('#home-particular').hasClass('js-hidden')) {
                    $('#home-profesional').fadeOut('400', 'linear', function() {
                        $('#home-particular').fadeIn();
                        $('#home-profesional').addClass('js-hidden');
                        $('#home-particular').removeClass('js-hidden');
                    });
                    $('#btn-home-profesional').addClass('btn-tertiary');
                    $('#btn-home-profesional').removeClass('btn-primary');
                    $('#btn-home-particular').removeClass('btn-tertiary');
                    $('#btn-home-particular').addClass('btn-primary');
                }
            });

            $('#btn-gasoleo-a').click(function() {
                if ($('#gasoleo-a').hasClass('js-hidden')) {
                    $('#gasoleo-b').fadeOut('400', 'linear', function() {
                        $('#gasoleo-a').fadeIn('slow');
                        $('#gasoleo-a').removeClass('js-hidden');
                        $('#gasoleo-b').addClass('js-hidden');
                    });
                    $('#span-gasoleo-a').show();
                    $('#btn-gasoleo-a').hide();
                    $('#span-gasoleo-b').hide();
                    $('#btn-gasoleo-b').show();
                }
            });

            $('#btn-gasoleo-b').click(function() {
                if ($('#gasoleo-b').hasClass('js-hidden')) {
                    $('#gasoleo-a').fadeOut('400', 'linear', function() {
                        $('#gasoleo-b').fadeIn('slow');
                        $('#gasoleo-b').removeClass('js-hidden');
                        $('#gasoleo-a').addClass('js-hidden');
                    });
                    $('#span-gasoleo-b').show();
                    $('#btn-gasoleo-b').hide();
                    $('#span-gasoleo-a').hide();
                    $('#btn-gasoleo-a').show();
                }
            });

            $('#btn-tarjetas-profesional').click(function () {
                if ($('#tarjetas-profesional').hasClass('js-hidden')) {
                    $('#tarjetas-particular').fadeOut('400', 'linear', function () {
                        $('#tarjetas-profesional').fadeIn('slow');
                        $('#tarjetas-profesional').removeClass('js-hidden');
                        $('#tarjetas-particular').addClass('js-hidden');
                    });
                }
            });

            $('#btn-tarjetas-particular').click(function () {
                if ($('#tarjetas-particular').hasClass('js-hidden')) {
                    $('#tarjetas-profesional').fadeOut('400', 'linear', function () {
                        $('#tarjetas-particular').fadeIn();
                        $('#tarjetas-profesional').addClass('js-hidden');
                        $('#tarjetas-particular').removeClass('js-hidden');
                    });
                }
            });

            //Control de Vista de Buscador de EESS
            $(".estaciones-buscador-list-bt").click(function() {
                console.log("lista");
                $( "#estaciones-mapa" ).fadeOut( "fast", "linear" );
                $( "#estaciones-list" ).fadeIn( "fast", "linear" );
                $( ".estaciones-buscador-list-bt").addClass("selected");
                $( ".estaciones-buscador-map-bt").removeClass("selected");
            });
            $(".estaciones-buscador-map-bt").click(function() {
                console.log("mapa");
                $( "#estaciones-list" ).fadeOut( "fast", "linear" );
                $( "#estaciones-mapa" ).fadeIn( "fast", "linear" );
                $( ".estaciones-buscador-map-bt").addClass("selected");
                $( ".estaciones-buscador-list-bt").removeClass("selected");
            });

            //Control de Avance de Formulario de Solicitud
            $(".form-next-btn").on("click", function(e) {
                e.preventDefault();

                var stepThisNum = $(this).data('step');
                var stepNextNum = stepThisNum + 1;
                var stepThis = ".form-step-" + stepThisNum;
                var stepNext = ".form-step-" + stepNextNum;

                $( stepThis+" .form-next").removeClass("py-5");
                $( stepNext ).fadeIn( "slow", "linear" );
                $(this).css( "display","none" );
                var newHref = '#form-step-'+stepNextNum;
                var newPosY = $(newHref).offset().top;
                newPosY = newPosY - 250;
                $('html, body').animate({
                    scrollTop: newPosY,
                }, 1000, 'linear');
            });

            //Control Selector de EESS de Entrega
            $( "input[name=entrega]" ).on( "click", function() {
                var radioSelected = $('#entrega-1:checked');
                if (radioSelected.length > 0) {
                    $(".entrega-row").fadeOut();
                }
                else {
                    $(".entrega-row").fadeIn();
                }
            });

            $('.filtros-botones .btn').click(function() {
                var target = $(this).data('target');

                $('.filtros-botones .btn').removeClass('active');
                $('.filtros-botones .btn[data-target='+target+']').addClass('active');
                $('.filtros-listado').addClass('d-none');
                $('#filtros-'+target).removeClass('d-none');
            });

            $('.alert-top-bar__close').click(function () {
                hideAlert();
            });

            $('.icon-banner--estacion').click(function () {
                showAlert();
            });

            $('.js-show-search').click(function (e) {
                e.preventDefault();
                showSearch();
            });

            $('.js-hide-search').click(function (e) {
                e.preventDefault();
                hideSearch();
            });
        });

        var videos = $(".video");

        videos.on("click", function () {
            playVideo($(this));
        });

        $('.js-play-video').click(function (e) {
            e.preventDefault();
            var elm = $('#'+ $(this).data('video'));

            playVideo(elm);
        });

        bs_input_file();
    });

    function playVideo(elm) {
        var conts = elm.contents(),
            le = conts.length,
            ifr = null;

        for (var i = 0; i < le; i++) {
            if (conts[i].nodeType == 8) ifr = conts[i].textContent;
        }
        if (!!ifr) {
            elm.addClass("player").html(ifr);
            elm.off("click");
        }
    }

    function bs_input_file() {
        $(".input-file").before(
            function () {
                if (!$(this).prev().hasClass('input-ghost')) {
                    var element = $("<input type='file' class='input-ghost' style='display: none; visibility:hidden; height:0'>");
                    element.attr("name", $(this).attr("name"));
                    element.change(function () {
                        element.next(element).find('input').val((element.val()).split('\\').pop());
                    });
                    $(this).find("button.btn-choose").click(function () {
                        element.click();
                    });
                    $(this).find("button.btn-reset").click(function () {
                        element.val(null);
                        $(this).parents(".input-file").find('input').val('');
                    });
                    $(this).find('input').css("cursor", "pointer");
                    $(this).find('input').mousedown(function () {
                        $(this).parents('.input-file').prev().click();
                        return false;
                    });
                    return element;
                }
            }
        );
    }

    function showAlert() {
        $('.alert-top-bar').removeClass('d-none');
        var padding = 0;
        var alert_height = $('.alert-top-bar').outerHeight();
        var navbar_light_height = $('.navbar-light').outerHeight();
        var navbar_dark_height = $('.navbar-dark').outerHeight();

        padding = alert_height;
        if ($('.navbar-dark').is(":visible")) {
            padding += navbar_dark_height;
        }

        if ($('.navbar-dark').is(":visible")) {
            $('.navbar-dark').css('margin-top', alert_height + 'px');
        }
        $('.navbar-light').css('margin-top', padding + 'px');
        padding += navbar_light_height;
        $('body').css('padding-top', padding + 'px');

    }

    function hideAlert() {
        var padding = 0;

        var navbar_light_height = $('.navbar-light').outerHeight();
        var navbar_dark_height = $('.navbar-dark').outerHeight();

        if ($('.navbar-dark').is(":visible")) {
            padding += navbar_dark_height;
        }

        if ($('.navbar-dark').is(":visible")) {
            $('.navbar-dark').css('margin-top', '0');
        }
        $('.navbar-light').css('margin-top', padding + 'px');
        padding += navbar_light_height;
        $('body').css('padding-top', padding + 'px');
        $('.alert-top-bar').addClass('d-none');
    }

    function showSearch() {
        $('#navbarResponsive').attr('style', 'display: none !important');
        $('.search-bar').removeClass('d-none');
    }

    function hideSearch() {
        $('.search-bar').addClass('d-none');
        $('#navbarResponsive').attr('style', '');
    }

})(jQuery, window, document);
